<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-05-07 18:12:02
-->
<template>
  <div class="addTopic">
    <div class="second-level">
      <div class="return-tit">
        <div class="f14 cu-p" @click="_backBtn"><i class="el-icon-back mr10"></i>返回</div>
        <span class="division">|</span>
        <div class="f16 col333">新增题目</div>
      </div>
    </div>
    <div class="fx-row content">
      <div class="left">
        <com-title title="基本字段" />
        <div class="sm-tit">点击添加到表单编辑区</div>
        <div class="topic-type-list fx-sw-cen">
          <div v-for="(item,index) in topicTypeList" :key="index" @click="_handlerType(item)">
            <img src="@/assets/img/topic1.png" v-if="item.questionType === 'SINGLE_CHOICE_TYPE'" alt="">
            <img src="@/assets/img/topic2.png" v-if="item.questionType === 'MULTIPLE_CHOICE_TYPE'" alt="">
            <img src="@/assets/img/topic3.png" v-if="item.questionType === 'JUDGMENT_TYPE'" alt="">
            <img src="@/assets/img/topic4.png" v-if="item.questionType === 'GAP_FILLING_TYPE'" alt="">
            <img src="@/assets/img/topic5.png" v-if="item.questionType === 'ESSAY_QUESTION_TYPE'" alt="">
            <span>{{ item.questionTypeValue }}</span>
          </div>
        </div>
      </div>
      <div class="middle fx-1" style="padding:0px 16px;">
        <topic-list
          v-if="optionList.length"
          :topicList="optionList"
          @changeOptionSort="changeOptionSort"
          @editOption="editOption"
          @deleteItem="deleteItem"
          @changeTopicType='_changeTopicType'/>
        <span style="margin: 20px;" v-else>暂无题目，请添加</span>
      </div>
      <div class="right">
        <!-- 题目类型---提交题目-->
        <topic-options
          ref="topicOptionRef"
          v-if="chooseTypeItem"
          :topicType="chooseTypeItem"
          :currentTopicData="currentTopicData"
          @submitOption="_getAddOption"
          @topicTitleInput="_topicTitleInput"
          @topicContentInput="_topicContentInput"
          @topicScoreInput="_topicScoreInput"
          @optionItemInput="_optionItemInput"
          @optionItemAddOrDelete="_optionItemAddOrDelete"
          @radioSelectInput="_radioSelectInput"
          @checkSelectChange="_checkSelectChange"
          @answerInput="_answerInput"
          @analysisInput="_analysisInput"
          @topicPictureUpload="_topicPictureUpload"
          @analysePictureUpload="_analysePictureUpload"
          @otherInstructionsInput="_otherInstructionsInput" />
      </div>
    </div>
    <div class="footer-btn">
      <el-button @click="_previewPaper">预览</el-button>
      <el-button type="primary" @click="_saveTopicData()">保存</el-button>
    </div>
    <!-- 预览试卷--弹框--开始 -->
    <previewExamPaper
      v-if="previewExamShow"
      :previewExamShow="previewExamShow"
      :paperId="paperId"
      :questionsGroupList="questionsGroupList"
    ></previewExamPaper>
    <!-- 预览试卷--弹框--结束 -->
  </div>
</template>
<script>
import TopicList from './topicModule/topicList.vue'
import TopicOptions from './topicModule/topicOptions.vue'
import comTitle from '@/components/comTitle'
import { topicManageManyAddApi } from '@/api/examApi'
import previewExamPaper from '../../examPaperManage/modules/previewExamPaper.vue'
export default {
  components: { TopicList, TopicOptions, comTitle, previewExamPaper },
  data () {
    return {
      chooseTypeItem: null, // 选中的类型
      optionList: [],
      editOptionIndex: null,
      topicTypeList: [
        { questionType: 'SINGLE_CHOICE_TYPE', questionTypeValue: '单项选择' },
        { questionType: 'MULTIPLE_CHOICE_TYPE', questionTypeValue: '多项选择' },
        { questionType: 'JUDGMENT_TYPE', questionTypeValue: '判断题' },
        { questionType: 'GAP_FILLING_TYPE', questionTypeValue: '填空题' },
        { questionType: 'ESSAY_QUESTION_TYPE', questionTypeValue: '问答题' }
      ],
      previewExamShow: false,
      paperId: '', // 考试id
      questionsGroupList: [],
      // 单项选择默认数据
      selectListData: {
        questionBankId: this.$route.query.questionBankId,
        questionType: 'SINGLE_CHOICE_TYPE', // 题类型--单项选择
        questionTypeValue: '单项选择',
        title: '题目', // 题目
        content: '题目说明', // 题目说明
        contentUrl: '', // 说明图片
        score: 5, // 分数
        options: [
          { optionName: '选项一', label: '选项1' },
          { optionName: '选项二', label: '选项2' },
          { optionName: '选项三', label: '选项3' }
        ], // 选项1
        answer: '选项1', // 答案
        analysis: '试题答案解析', // 答案解析
        analysisUrl: '', // 答案解析图片
        otherInstructions: '' // 其他说明
      },
      currentTopicData: {} //
    }
  },
  created () {
    this.chooseTypeItem = this.topicTypeList[0]// 默认单选框
    this.optionList[0] = this._getTopicDataByType(this.chooseTypeItem.questionType)
  },
  methods: {
    // 拖拽排序
    changeOptionSort (evt) {
      console.log(evt, 'evt')
      const that = this
      if (that.optionList.length <= 1) return false
      that.optionList.splice(evt.newIndex, 0, that.optionList.splice(evt.oldIndex, 1)[0])
      const newArray = that.optionList.slice(0)
      // // 重新赋值来刷新视图
      that.optionList = [] // 必须有此步骤，不然拖拽后回弹
      that.$nextTick(function () {
        that.optionList = newArray // 重新赋值，用新数据来刷新视图
        console.log(that.optionList, '111')
      })
    },
    // 预览试卷
    _previewPaper () {
      console.log(this.optionList, 'this.optionList')
      this.questionsGroupList = this._getNeedShowData(this.optionList)
      this.previewExamShow = true
    },
    // 试卷预览-- 回显所需数据格式
    _getNeedShowData (questionsGroupList) {
      const tempGroupTopicData = []
      tempGroupTopicData.push({
        groupIndex: 0,
        groupName: '',
        groupSort: 1,
        questionInfo: JSON.parse(JSON.stringify(questionsGroupList))
      })
      return tempGroupTopicData
    },
    // 列表删除某项
    deleteItem (index) {
      this.optionList.splice(index, 1)
    },
    // 编辑列表
    editOption (item, index) {
      this.editOptionIndex = index
      this.$refs.topicOptionRef.form = item
    },
    // 点击左边题类型
    _handlerType (item) {
      const tempTopicData = this._getTopicDataByType(item.questionType)
      this.optionList.push(tempTopicData)
    },
    // 保存题目
    _saveTopicData () {
      const param = this.optionList.map((item) => {
        return {
          questionBankId: item.questionBankId,
          questionType: item.questionType,
          title: item.title,
          content: item.content,
          contentUrl: item.contentUrl ? item.contentUrl.join(',') : '',
          answer: item.answer,
          score: item.score,
          options: JSON.stringify(item.options),
          analysis: item.analysis,
          analysisUrl: item.analysisUrl ? item.analysisUrl.join(',') : '',
          otherInstructions: item.otherInstructions
        }
      })
      if (param.length === 0) {
        return this.$message.error('请添加题目！')
      }
      // 校验填空题---的空与答案的数量是否一致--start
      const tempQuestionAllArr = JSON.parse(JSON.stringify(param))
      let fillIndex = 0
      for (let i = 0; i < tempQuestionAllArr.length; i++) {
        // 校验填空题---的空与答案的数量是否一致---------------------
        if (tempQuestionAllArr[i].questionType === 'GAP_FILLING_TYPE') {
          fillIndex = fillIndex + 1
          tempQuestionAllArr[i].fillAnswer = tempQuestionAllArr[i].title.split('_').map((item) => { return { answer: '' } })
          const fillNum = tempQuestionAllArr[i].fillAnswer.length - 1
          const answerNum = tempQuestionAllArr[i].answer.split('。').length
          if (fillNum === 0) {
            return this.$message.error('第' + (i + 1) + '题为填空题，题目不能没有填空符！')
          }
          if (fillNum !== answerNum) {
            return this.$message.error('第' + (i + 1) + '题为填空题，题目填空符与答案个数不匹配！')
          }
        }
        // 题目-----校验
      }
      // 校验填空题---的空与答案的数量是否一致--end
      topicManageManyAddApi({ questionInfos: param }).then((res) => {
        if (res.code === 200) {
          this.$message.success('新增成功！')
          this.$router.go(-1)
        }
      })
    },
    _backBtn () {
      this.$router.go(-1)
    },
    // 新增小题
    // 接收子组件提交的表单
    _getAddOption (data) {
      console.log(data, 'form')
      // 编辑
      if (this.editOptionIndex) {
        this.optionList[this.editOptionIndex] = data
      } else {
        this.optionList.push(data)
      }
      console.log(this.optionList, 'this.optionListthis.optionList-----0099')
    },
    _getTopicDataByType (type) {
      console.log(this.selectListData, 'selectListDataselectListData')
      let questionTypeData
      switch (type) {
        case 'SINGLE_CHOICE_TYPE':
          questionTypeData = JSON.parse(JSON.stringify(this.selectListData))
          questionTypeData.answerCheck = questionTypeData.answer.split(',')
          break
        case 'MULTIPLE_CHOICE_TYPE':
          questionTypeData = JSON.parse(JSON.stringify(this.selectListData))
          questionTypeData.questionTypeValue = '多项选择'
          questionTypeData.questionType = type
          questionTypeData.answer = '选项1,选项2'
          questionTypeData.answerCheck = questionTypeData.answer.split(',')
          break
        case 'JUDGMENT_TYPE':
          questionTypeData = JSON.parse(JSON.stringify(this.selectListData))
          questionTypeData.questionType = type
          questionTypeData.questionTypeValue = '判断题'
          questionTypeData.options = [
            { optionName: '正确', label: '选项1' },
            { optionName: '错误', label: '选项2' }]
          questionTypeData.answer = '选项1'
          questionTypeData.answerCheck = questionTypeData.answer.split(',')
          break
        case 'GAP_FILLING_TYPE':
          questionTypeData = JSON.parse(JSON.stringify(this.selectListData))
          questionTypeData.questionType = type
          questionTypeData.questionTypeValue = '填空题'
          questionTypeData.options = []
          questionTypeData.answer = '正确答案一。正确答案二'
          questionTypeData.analysis = '正确答案一。正确答案二'
          break
        case 'ESSAY_QUESTION_TYPE':
          questionTypeData = JSON.parse(JSON.stringify(this.selectListData))
          questionTypeData.questionType = type
          questionTypeData.questionTypeValue = '问答题'
          questionTypeData.options = []
          break
        default:
          break
      }
      return questionTypeData
    },
    _changeTopicType (item) {
      console.log(item, 'item初始化')
      this.chooseTypeItem = {
        questionType: item.questionType,
        questionTypeValue: item.questionTypeValue
      }
      this.currentTopicData = { ...item }
    },
    // 题目输入
    _topicTitleInput (item) {
      this.optionList[item.index].title = item.title
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], title: this.optionList[item.index].title })
    },
    // 题目说明输入
    _topicContentInput (item) {
      this.optionList[item.index].content = item.content
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], content: this.optionList[item.index].content })
    },
    // 分数输入
    _topicScoreInput (item) {
      this.optionList[item.index].score = item.score
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], score: this.optionList[item.index].score })
    },
    // 选项输入
    _optionItemInput (item) {
      console.log(item)
      this.optionList[item.index].options[item.opIndex].optionName = item.options[item.opIndex].optionName
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], options: this.optionList[item.index].options })
    },
    // 选项添加
    _optionItemAddOrDelete (item) {
      this.optionList[item.index].options = item.options
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], options: this.optionList[item.index].options })
    },
    // 单选组(单选题or判断题)
    _radioSelectInput (item) {
      this.optionList[item.index].answer = item.answer
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], answer: this.optionList[item.index].answer })
    },
    // 复选项
    _checkSelectChange (item) {
      this.optionList[item.index].answerCheck = item.answerCheck
      this.optionList[item.index].answer = this.optionList[item.index].answerCheck.join(',')
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], answer: this.optionList[item.index].answer, answerCheck: this.optionList[item.index].answerCheck })
    },
    // 答案
    _answerInput (item) {
      this.optionList[item.index].answer = item.answer
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], answer: this.optionList[item.index].answer })
    },
    // 答案解析
    _analysisInput (item) {
      this.optionList[item.index].analysis = item.analysis
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], analysis: this.optionList[item.index].analysis })
    },
    // 其他说明
    _otherInstructionsInput (item) {
      this.optionList[item.index].otherInstructions = item.otherInstructions
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], otherInstructions: this.optionList[item.index].otherInstructions })
    },
    // 题目说明---上传图片
    _topicPictureUpload (item) {
      this.optionList[item.index].contentUrl = item.contentUrl
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], contentUrl: this.optionList[item.index].contentUrl })
    },
    // 答案解析---上传图片
    _analysePictureUpload (item) {
      this.optionList[item.index].analysisUrl = item.analysisUrl
      this.$set(this.optionList, item.index, { ...this.optionList[item.index], analysisUrl: this.optionList[item.index].analysisUrl })
    }

  }
}
</script>
<style scoped lang="scss">
  .second-level{
    border-bottom: 1px solid #F3F3F3;
    .return-tit{
      margin-bottom: 0;
    }
  }
  .addTopic{
    background: #fff;
    .content{
      height: calc(100vh - 230px);
    }
  }
  .left{
    width: 240px;
    padding: 16px 12px 0px 0px;
    .title{
      font-size: 16px;
      color: #000;
      border-left: 3px solid #0089FF;
      padding-left: 8px;
      line-height: 16px;
    }
    .sm-tit{
      font-size: 14px;
      color: #666;
      margin: 16px 0;
    }
    .topic-type-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      >div{
        width: 105px;
        border: 1px solid #DCDCDC;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px;
        margin-bottom: 12px;
        cursor: pointer;
        img{
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
      }
    }
  }
  .middle{
    overflow-y: scroll;
    border-left: 1px solid #F3F3F3;
    border-right: 1px solid #F3F3F3;
  }
  .right{
    overflow-y: scroll;
    width: 300px;
    padding: 16px;
  }
  .middle{
    /deep/.el-radio,/deep/.el-checkbox{
     display: block;
     margin: 16px 0;
   }
  }
  .footer-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px 0;
    box-shadow: 0px -4px 8px 0px rgba(0,0,0,0.04);
  }
  .upload-block{
    width: 90px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 4px;
    color: #0089FF;
    cursor: pointer;
    border: 1px dotted #0089FF;
    margin-top: 8px;
    background: rgba(0, 137, 255, 0.05);
    i{
      margin-right: 3px;
    }
  }
</style>
